import React, { ReactElement } from "react";
import colors from "@styles/variables/compareCRMPricesVariables.module.scss";

import ImproveCustomerServiceIcon from "../icons/improveCustomerService";
import IncreaseInSalesIcon from "../icons/increaseInSales";
import BetterAnalyticsIcon from "../icons/betterAnalytics";
import HigherEfficiencyIcon from "../icons/higherEfficiency";
import BetterKnowledgeSharingIcon from "../icons/betterKnowledgeSharing";
import MoreTransparencyIcon from "../icons/moreTransparency";
import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        title: "Enhance Customer Service",
        description:
            "By managing all your contacts and consolidating lead and customer information, your CRM empowers you with easy access to essential data to better understand customer behavior.",
        icon: <ImproveCustomerServiceIcon />,
    },
    {
        title: "Boost Sales",
        description:
            "Streamlining and improving the sales process, automating tasks, building a sales pipeline, and analyzing sales data ultimately lead to one outcome: increased productivity and sales.",
        icon: <IncreaseInSalesIcon />,
    },
    {
        title: "Improve Analytics",
        description:
            "Analytical CRM tools make your data available, intelligible, and relevant to your business needs. You can generate detailed reports on your sales, finance, and marketing data.",
        icon: <BetterAnalyticsIcon />,
    },
    {
        title: "Increase Efficiency",
        description:
            "Centralizing your primary day-to-day business functions in one place leads to better workflow, easier collaboration between team members, and improved project management.",
        icon: <HigherEfficiencyIcon />,
    },
    {
        title: "Facilitate Knowledge Sharing",
        description:
            "Miscommunication and information gaps are significant time-wasters. When employees spend time learning what others already know, valuable time is lost. A CRM system solves this issue by promoting better knowledge sharing.",
        icon: <BetterKnowledgeSharingIcon />,
    },
    {
        title: "Enhance Transparency",
        description:
            "A CRM system fosters greater organizational transparency by assigning tasks, displaying work, and clarifying every employee's position and authority.",
        icon: <MoreTransparencyIcon />,
    },
];

export default function WhyUs(): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    return (
        <>
            <FeatureList
                featureList={whyUsList}
                mainTitle=" Benefits of CRM"
                colors={{
                    mainTitleColor: colors.whyUsTitle,
                    titleColor: colors.whyUsItemTitle,
                    descriptionColor: colors.whyUsItemBrief,
                }}
                classes={{
                    mainTitleClasses:
                        "text-center font-bold text-xl lg:text-4xl mb-14",
                    innerSectionClasses: "lg:flex flex-wrap justify-center",
                    oneBoxClasses: "text-center mb-12 lg:mb-20 w-full lg:w-1/3",
                    titleClasses: "font-medium text-lg lg:text-xl mt-6",
                    iconClasses: "children:mx-auto",
                    descriptionClasses:
                        "font-light text-sm text-center w-4/5 mx-auto mt-2",
                }}
            />
            <div className="text-center mb-10">
                <button
                    onClick={() => scrollToTop()}
                    className={`bg-[#035670] text-white py-4 sm:px-11 px-4 sm:text-lg font-semibold rounded-lg hover:-translate-y-2 transition-all hover:transition-all`}
                >
                    Get My Free CRM Quote Now
                </button>
            </div>
        </>
    );
}
